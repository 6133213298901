import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { PRIVACY_POLICY_URL } from "@src/constants/urls";
import { openBrowser } from "@src/lib/deprecatedCode";

const PrivacyPolicyNotificationPage = ({ match, history }) => {
  const onNotificationPreferenceClick = () => history.push(`${match.url}/notification-preference`);
  const onPrivacyPolicyClick = () => openBrowser(PRIVACY_POLICY_URL);

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="Account" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle style={{ marginLeft: "8px" }}>Privacy and Notifications</IonTitle>
        </IonToolbar>
      </IonHeader>
      {/* <HeaderType2 title={'Privacy and Notifications'} titleBack={'Account'} backLink={'/home/account'}> </HeaderType2>*/}
      <IonContent>
        <IonItem button onClick={onNotificationPreferenceClick} detail>
          <IonLabel>
            <h4>Notification Preferences</h4>
          </IonLabel>
        </IonItem>
        <IonItem button onClick={onPrivacyPolicyClick} detail>
          <IonLabel>
            <h4>Privacy Policy</h4>
          </IonLabel>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export { PrivacyPolicyNotificationPage };

import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

const PrivacyPolicyPage = ({ match, history }) => {
  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="Privacy" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle>Privacy Policy</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem lines="none">Privacy Policy Screen</IonItem>
      </IonContent>
    </IonPage>
  );
};

export { PrivacyPolicyPage };

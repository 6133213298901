import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import request from "superagent";

import { getAuthHeader } from "../../superagent";
import { Response } from "../../utils/response";

const setNotificationPreference = async (notificationPreferences): Promise<Response> => {
  return await request
    .patch(`${environmentConfig.REACT_APP_BASE_API_URL}/notificationPreferences/update`)
    .set(await getAuthHeader())
    .send({ ...notificationPreferences })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const getNotificationPreference = async (): Promise<Response> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/notificationPreferences/fetch`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export { setNotificationPreference, getNotificationPreference };

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { SIGN_NEW_AGREEMENT_PATH } from "@src/appV2/Agreements/paths";
import { RootPaths } from "@src/appV2/App/paths";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { ActionType } from "@store/session";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { useSession } from "../store/helperHooks";

import "./style.scss";

const SignICAgreementModal = () => {
  const dispatch = useDispatch();

  const { popups } = useSession();

  const { visible: shouldShowICAgreementPopup = false } = popups?.ICAgreement ?? {};

  const history = useHistory();

  return (
    <>
      {shouldShowICAgreementPopup && (
        <Dialog
          open={shouldShowICAgreementPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            We've updated our Independent Contractor Agreement
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You will be asked to verify your phone number and accept our updated Independent
              Contractor agreement, which governs your access to Clipboard's technology platform and
              applications.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "flex-end" }}>
            <Button
              variant="text"
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.SIGN_NEW_AGREEMENT_MODAL_DISMISS);
                dispatch({ type: ActionType.HIDE_IC_AGREEMENT_POPUP });
              }}
            >
              Dismiss
            </Button>
            <Button
              variant="text"
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.SIGN_NEW_AGREEMENT_MODAL_CONTINUE);
                dispatch({ type: ActionType.HIDE_IC_AGREEMENT_POPUP });
                history.push(`${RootPaths.HOME}/${SIGN_NEW_AGREEMENT_PATH}`);
              }}
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export { SignICAgreementModal };

import { ProfileUpdatePopup } from "./ProfileUpdatePopup";
import { SignICAgreementModal } from "./SignICAgreementModal";
import { HolidayAlert } from "../holiday/holidayAlert";

export function PopupAlerts() {
  return (
    <>
      <ProfileUpdatePopup />
      <SignICAgreementModal />
      <HolidayAlert />
    </>
  );
}

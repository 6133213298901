import { isDefined } from "@clipboard-health/util-ts";
import { IonBadge, IonButton, IonCardSubtitle, IonIcon, IonRow } from "@ionic/react";
import { chatbubblesOutline } from "ionicons/icons";
import moment from "moment";
import { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { FCMRequestData } from "./interfaces";
import { formatDollarsAsUsd } from "../../utils/currency";
import { useAppSelector } from "../store";
import { useSession } from "../store/helperHooks";

const PendingSentHomeRequestsCard: FC<{
  fcmRequest: FCMRequestData;
}> = ({ fcmRequest }) => {
  const history = useHistory();
  const { channels } = useAppSelector((store) => store.chatStore);
  const { userId } = useSession();
  const currentChannel = channels.find(
    (channel) => channel.url === `${fcmRequest.shift.facilityId}_${userId}`
  );

  const formattedDate = `${moment(fcmRequest.shift.start).format(
    "dddd, MMMM DD hh:mm a"
  )} - ${moment(fcmRequest.shift.end).format("hh:mm a")}`;

  const onChatButtonClick = useCallback(() => {
    history.push(`/home/account/chat/${fcmRequest.shift.facilityId}`);
  }, [history, fcmRequest.shift.facilityId]);

  const showChatButton = isDefined(currentChannel?.url);
  const showUnreadMessageCount = (currentChannel?.unreadMessageCount || 0) > 0;

  return (
    <div className="new-flow shifts ongoing">
      <IonCardSubtitle className="title no-text-transform">{formattedDate}</IonCardSubtitle>
      <IonCardSubtitle className="title no-text-transform">
        {fcmRequest.shift.facility?.name}
      </IonCardSubtitle>
      <IonRow>
        {fcmRequest.earnings > 0 && (
          <div style={{ fontWeight: "bold", marginBlock: "6px" }}>
            <span style={{ color: "#3BB273" }}>{formatDollarsAsUsd(fcmRequest.earnings)}</span>{" "}
            <span>pending facility approval</span>
          </div>
        )}
      </IonRow>
      <IonRow>
        {showChatButton && (
          <IonButton
            onClick={onChatButtonClick}
            fill="outline"
            style={{ height: 34, color: "#4F4F4F", padding: 0 }}
            color="dark"
            shape="round"
            strong={true}
          >
            <SpacedElements gap={14}>
              <IonIcon style={{ height: 20, width: 20 }} icon={chatbubblesOutline} mode="ios" />
              <span style={{ color: "#4F4F4F" }}>Facility Chat</span>
              {showUnreadMessageCount && (
                <IonBadge color="danger">{currentChannel?.unreadMessageCount}</IonBadge>
              )}
            </SpacedElements>
          </IonButton>
        )}
      </IonRow>
    </div>
  );
};

const SpacedElements = ({ children, gap }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap, height: "100%" }}>{children}</div>
  );
};

export { PendingSentHomeRequestsCard };
